import {
  Elevator,
  ElevatorCopyableFieldsForBasicSurvey,
  ElevatorCopyableFieldsForExtendedSurvey,
} from "../models/elevator";
import { SurveyFormVm } from "../views/survey/SurveyForm";

type SurveyStats = {
  identificationCount: number;
  identificationCompletedCount: number;
  generalCount: number;
  generalCompletedCount: number;
  technicalCount: number;
  technicalCompletedCount: number;
  totalCount: number;
  totalCompletedCount: number;
};

export const camelCaseToTitleCase = (str: string): string => {
  const spacedStr = str.replace(/([a-z])([A-Z])/g, "$1 $2");
  const titleCaseStr = spacedStr.replace(/\b\w/g, (char) => char.toUpperCase());
  return titleCaseStr;
};

const checkIfFieldIsCompleted = (field: unknown): boolean => {
  if (field === null || field === undefined) {
    return false;
  }

  if (typeof field === "string") {
    return field.length > 0;
  }

  if (Array.isArray(field)) {
    return field.length > 0;
  }

  return true;
};

export const calculateCompleted = (
  elevator: SurveyFormVm,
  mode: "basic" | "extended",
): SurveyStats => {
  const {
    name,
    unitNumber,
    ahj,
    ahjId,
    certificatePicture,
    systemType,
    condition,
    designQuality,
    facilityType,
    typeOfUse,
    traffic,
    environment,
    doorType,
    landings,
    frontLandings,
    rearOrSideLandings,
    fixturePictures,
    hoistwayAccess,
    fireService,
    machineRoomPictures,
    machineInstallYear,
    technology,
    isThereAMachineRoom,
    controllerPicture,
    controllerManufacturerAndModel,
    controllerInstallYear,
    notes,
    additionalPhotos,
    sensorEdgeBrandAndModel,
    doorRestrictorsType,
    doorOperatorManufacturerAndType,
    topOfCarPictures,
    motorControllerType,
    hasCounterweightSafeties,
    flexHoseHydro,
    governerType,
    pressureSwitchHydro,
    guideType,
    driveSheaveLiners,
    suspensionMeans,
    suspensionMeansInstallYear,
    ropeDataTagPicture,
    brakeType,
    batteryLowering,
    controllerValveManufacturerAndModel,
    valvePictures,
    bufferType,
    bufferSwitches,
    compensationCablesChains,
    overspeedValveHydro,
    plungerGripperHydro,
    pitPictures,
  } = elevator;

  const identificationFields = [
    name,
    unitNumber,
    ahj,
    ahjId,
    certificatePicture,
  ].map((field) => checkIfFieldIsCompleted(field));

  const generalFields = [
    isThereAMachineRoom,
    systemType,
    condition,
    facilityType,
    traffic,
    doorType,
    landings,
    frontLandings,
    rearOrSideLandings,
    fixturePictures,
    hoistwayAccess,
    fireService,
    machineRoomPictures,
    machineInstallYear,
    technology,
    controllerPicture,
    controllerManufacturerAndModel,
    controllerInstallYear,
    notes,
    additionalPhotos,
  ].map((field) => checkIfFieldIsCompleted(field));

  const technicalFields = (
    mode === "extended"
      ? [
          sensorEdgeBrandAndModel,
          doorRestrictorsType,
          doorOperatorManufacturerAndType,
          topOfCarPictures,
          motorControllerType,
          hasCounterweightSafeties,
          flexHoseHydro,
          governerType,
          pressureSwitchHydro,
          guideType,
          driveSheaveLiners,
          suspensionMeans,
          suspensionMeansInstallYear,
          ropeDataTagPicture,
          brakeType,
          batteryLowering,
          controllerValveManufacturerAndModel,
          valvePictures,
          bufferType,
          bufferSwitches,
          compensationCablesChains,
          overspeedValveHydro,
          plungerGripperHydro,
          pitPictures,
          typeOfUse,
          designQuality,
          environment,
        ]
      : []
  ).map((field) => checkIfFieldIsCompleted(field));

  const identificationCompletedCount = identificationFields.filter(
    (i) => i,
  ).length;
  const generalCompletedCount = generalFields.filter((i) => i).length;
  const technicalCompletedCount = technicalFields.filter((i) => i).length;

  const totalCount =
    identificationFields.length + generalFields.length + technicalFields.length;

  const totalCompletedCount =
    identificationCompletedCount +
    generalCompletedCount +
    technicalCompletedCount;

  return {
    identificationCount: identificationFields.length,
    identificationCompletedCount,
    generalCount: generalFields.length,
    generalCompletedCount,
    technicalCount: technicalFields.length,
    technicalCompletedCount,
    totalCount,
    totalCompletedCount,
  };
};

type CopyElevatorOpts = {
  from: Elevator;
  to: Elevator;
  mode?: "basic" | "extended";
};

export const cloneElevator = ({
  from,
  to,
  mode = "basic",
}: CopyElevatorOpts): Elevator => {
  const fieldsToCopy =
    mode === "basic"
      ? ElevatorCopyableFieldsForBasicSurvey.parse(from)
      : ElevatorCopyableFieldsForExtendedSurvey.parse(from);

  return {
    ...to,
    ...fieldsToCopy,
  };
};

export const getOobCount = async () => {
  const response = await fetch(
    "http://127.0.0.1:9099/emulator/v1/projects/intelevator-d72e8/oobCodes",
  ).then((resp) => resp.json());
  return response.oobCodes.length;
};
