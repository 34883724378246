import z from "zod";
import { coerceUndefinedToNull } from "./utils";

export const yesNoOption = { id: true, label: "Yes" };
export const noOption = { id: false, label: "No" };
export const yesNoOptions = [yesNoOption, noOption] as const;
export type YesNoOption = (typeof yesNoOptions)[number];

export const yesNoOptionToBool = (value: YesNoOption): boolean => {
  return value.id;
};

export const conditionOptions = ["good", "fair", "poor"] as const;
export type ConditionOption = (typeof conditionOptions)[number];

export const conditionValueFormatter = (value: string | null): string => {
  switch (value) {
    case "good":
      return "Good";
    case "fair":
      return "Fair";
    case "poor":
      return "Poor";
  }

  throw new Error(`Invalid radio option: ${value}`);
};

export const trafficOptions = ["high", "medium", "low"] as const;

export const trafficValueFormatter = (value: string | null): string => {
  switch (value) {
    case "high":
      return "High";
    case "medium":
      return "Medium";
    case "low":
      return "Low";
  }

  throw new Error(`Invalid traffic option: ${value}`);
};

// TODO: Possibly find a better place for this
export const yearOptions = ((): string[] => {
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: currentYear - 1950 + 1 }, (_, i) =>
    (1950 + i).toString(),
  ).reverse();
  years.push("Pre 1950");

  return years;
})();

export const systemTypeOptions = [
  "Hydraulic",
  "Hydro Direct",
  "Hydro MRL",
  "Hydro Telescopic",
  "Man Lift (SPPE)",
  "Traction Geared",
  "Traction Gearless",
  "Traction MRL",
  "Telescopic",
  "Roped Hydro",
  "Gearless",
  "Escalator",
  "Moving Walk",
  "Dumbwaiter",
  "Vertical Platform Lift (VPL)",
  "Wheelchair Lift (WCL)",
  "Handicap Lift",
  "Other",
] as const;

export type SystemTypeOption = (typeof systemTypeOptions)[number];

export const fireServiceOptions = [
  "phase-1-only",
  "phase-1-and-2",
  "none",
] as const;

export const fireServiceValueFormatter = (value: string | null): string => {
  switch (value) {
    case "phase-1-only":
      return "Phase I (only)";
    case "phase-1-and-2":
      return "Phase I & II";
    case "none":
      return "None";
  }

  throw new Error(`Invalid fire service value: ${value}`);
};
export const environmentOptions = [
  "clean",
  "grimy",
  "dusty",
  "filthy",
  "salty",
] as const;

export const environmentValueFormatter = (value: string | null): string => {
  switch (value) {
    case "clean":
      return "Clean";
    case "grimy":
      return "Grimy";
    case "dusty":
      return "Dusty";
    case "filthy":
      return "Filthy";
    case "salty":
      return "Salty";
  }

  throw new Error(`Invalid environment value: ${value}`);
};

export const guideOptions = ["Slide Guide", "Roller Guide"] as const;

export const suspensionOptions = [
  "Wire Rope",
  "Flat Belt",
  "Coated Rope",
] as const;

export const motorControllerOptions = [
  "Across The Line Starter",
  "WYE - Delta Starter",
  "Solid State Starter",
  "Single Speed AC",
  "Two Speed AC",
  "MG (Motor Generator)",
  "SCR (Silicone Controlled Rectifier)",
  "VVVF (Variable Voltage Variable Frequency)",
] as const;

export const Elevator = z.object({
  id: z.string(),
  propertyId: z.string(),
  servmanId: coerceUndefinedToNull(z.string()),
  name: coerceUndefinedToNull(z.string()),
  unitNumber: coerceUndefinedToNull(z.string()),
  stateId: coerceUndefinedToNull(z.string()),
  ahj: coerceUndefinedToNull(z.string()),
  typeOfUse: coerceUndefinedToNull(z.string()),
  ahjId: coerceUndefinedToNull(z.string()),
  fireService: coerceUndefinedToNull(z.enum(fireServiceOptions)),
  facilityType: coerceUndefinedToNull(z.string()),
  traffic: coerceUndefinedToNull(z.enum(trafficOptions)),
  doorType: coerceUndefinedToNull(z.string()),
  condition: coerceUndefinedToNull(z.enum(conditionOptions)),
  designQuality: coerceUndefinedToNull(z.enum(conditionOptions)),
  usage: coerceUndefinedToNull(z.string()),
  environment: coerceUndefinedToNull(z.enum(environmentOptions)),
  technology: coerceUndefinedToNull(z.string()),
  usageType: coerceUndefinedToNull(z.string()),
  machineInstallYear: coerceUndefinedToNull(z.coerce.number().int()),
  controllerInstallYear: coerceUndefinedToNull(z.coerce.number().int()),
  doorPanels: coerceUndefinedToNull(z.string()),
  landings: coerceUndefinedToNull(z.coerce.number().int().gt(0)),
  frontLandings: coerceUndefinedToNull(z.coerce.number().int().gt(0)),
  rearOrSideLandings: coerceUndefinedToNull(z.coerce.number().int().gt(0)),
  location: coerceUndefinedToNull(z.string()),
  sensorEdgeBrandAndModel: coerceUndefinedToNull(z.string()),
  motorControllerType: coerceUndefinedToNull(z.enum(motorControllerOptions)),
  hasCounterweightSafeties: coerceUndefinedToNull(z.boolean()),
  flexHoseHydro: coerceUndefinedToNull(z.boolean()),
  motorGenerator: coerceUndefinedToNull(z.boolean()),
  governerType: coerceUndefinedToNull(z.string()),
  systemType: coerceUndefinedToNull(z.enum(systemTypeOptions)),
  pressureSwitchHydro: coerceUndefinedToNull(z.boolean()),
  driveSheaveLiners: coerceUndefinedToNull(z.boolean()),
  machineRoomLess: coerceUndefinedToNull(z.boolean()),
  coatedRopes: coerceUndefinedToNull(z.boolean()),
  pressureSwitch: coerceUndefinedToNull(z.boolean()),
  tractionLossDetection: coerceUndefinedToNull(z.boolean()),
  batteryLowering: coerceUndefinedToNull(z.boolean()),
  brakeType: coerceUndefinedToNull(z.string()),
  controllerManufacturerAndModel: coerceUndefinedToNull(z.string()),
  controllerValveManufacturerAndModel: coerceUndefinedToNull(z.string()),
  suspensionMeans: coerceUndefinedToNull(z.enum(suspensionOptions)),
  suspensionMeansInstallYear: coerceUndefinedToNull(z.coerce.number().int()),
  doorRestrictorsType: coerceUndefinedToNull(z.string()),
  guideType: coerceUndefinedToNull(z.enum(guideOptions)),
  doorOperatorManufacturerAndType: coerceUndefinedToNull(z.string()),
  hoistwayAccess: coerceUndefinedToNull(z.boolean()),
  fireServiceBasic: coerceUndefinedToNull(z.boolean()),
  fireServiceAdvanced: coerceUndefinedToNull(z.boolean()),
  bufferType: coerceUndefinedToNull(z.string()),
  bufferSwitches: coerceUndefinedToNull(z.boolean()),
  compensationCablesChains: coerceUndefinedToNull(z.boolean()),
  overspeedValveHydro: coerceUndefinedToNull(z.boolean()),
  plungerGripperHydro: coerceUndefinedToNull(z.boolean()),
  mainFloorOfEgress: coerceUndefinedToNull(z.boolean()),
  passThroughFloors: coerceUndefinedToNull(z.boolean()),
  notes: coerceUndefinedToNull(z.string()),
  certificatePicture: coerceUndefinedToNull(z.array(z.string())),
  fixturePictures: coerceUndefinedToNull(z.array(z.string())),
  machineRoomPictures: coerceUndefinedToNull(z.array(z.string())),
  controllerPicture: coerceUndefinedToNull(z.array(z.string())),
  additionalPhotos: coerceUndefinedToNull(z.array(z.string())),
  topOfCarPictures: coerceUndefinedToNull(z.array(z.string())),
  ropeDataTagPicture: coerceUndefinedToNull(z.array(z.string())),
  valvePictures: coerceUndefinedToNull(z.array(z.string())),
  pitPictures: coerceUndefinedToNull(z.array(z.string())),
  isThereAMachineRoom: coerceUndefinedToNull(z.boolean()),
});

export const ElevatorWithoutId = Elevator.omit({ id: true });

export const ElevatorCopyableFieldsForExtendedSurvey = Elevator.omit({
  id: true,
  name: true,
  unitNumber: true,
  ahj: true,
  ahjId: true,
  certificatePicture: true,
  fixturePictures: true,
  machineRoomPictures: true,
  controllerPicture: true,
  additionalPhotos: true,
  topOfCarPictures: true,
  ropeDataTagPicture: true,
  valvePictures: true,
  pitPictures: true,
});

export const ElevatorCopyableFieldsForBasicSurvey =
  ElevatorCopyableFieldsForExtendedSurvey.omit({
    doorRestrictorsType: true,
    sensorEdgeBrandAndModel: true,
    doorOperatorManufacturerAndType: true,
    governerType: true,
    brakeType: true,
    controllerValveManufacturerAndModel: true,
    bufferType: true,
    motorControllerType: true,
    hasCounterweightSafeties: true,
    flexHoseHydro: true,
    pressureSwitchHydro: true,
    guideType: true,
    driveSheaveLiners: true,
    suspensionMeans: true,
    suspensionMeansInstallYear: true,
    batteryLowering: true,
    bufferSwitches: true,
    compensationCablesChains: true,
    overspeedValveHydro: true,
    plungerGripperHydro: true,
  });

export const ExportElevator = Elevator.omit({
  id: true,
  propertyId: true,
  servmanId: true,
  certificatePicture: true,
  fixturePictures: true,
  machineRoomPictures: true,
  controllerPicture: true,
  additionalPhotos: true,
  topOfCarPictures: true,
  ropeDataTagPicture: true,
  valvePictures: true,
  pitPictures: true,
});

export type Elevator = z.infer<typeof Elevator>;
export type ElevatorWithoutId = z.infer<typeof ElevatorWithoutId>;
export type ElevatorCopyableFieldsForExtendedSurvey = z.infer<
  typeof ElevatorCopyableFieldsForExtendedSurvey
>;
export type ElevatorCopyableFieldsForBasicSurvey = z.infer<
  typeof ElevatorCopyableFieldsForBasicSurvey
>;

export type ElevatorInput = z.input<typeof Elevator>;

export type ExportElevator = z.infer<typeof ExportElevator>;
