import { isRouteErrorResponse, useRouteError } from "react-router-dom";

export const ErrorPage = () => {
  const error = useRouteError();
  return (
    <div className="flex items-center justify-center h-screen">
      <p className="text-gray-600 text-3xl">
        {isRouteErrorResponse(error) && error.status === 404
          ? "404 Page Not Found"
          : typeof error === "string"
            ? error
            : error &&
                typeof error === "object" &&
                "message" in error &&
                typeof error.message === "string"
              ? error.message
              : "An unknown error occurred"}
      </p>
    </div>
  );
};
